import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import App from './App.vue';

// CSS imports
import '@klett/kui/dist/kui.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Vue configuration
Vue.config.productionTip = false;

// FontAwesome configuration
library.add(fab, fad, far, fas);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// BootstrapVue configuration
Vue.use(BootstrapVue);

// Mount application
new Vue({
  render: (h) => h(App),
}).$mount('#app');
